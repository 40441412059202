import React from 'react'

import { Grid, Stack } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

import Text from '~/components/base/Text'
import TextBg from '~/components/common/TextBg'

import CalendarIcon from '~/assets/icons/home/calendar.svg'
import KeyIcon from '~/assets/icons/home/key.svg'
import RedLineIcon from '~/assets/icons/home/red-line.svg'
import TickIcon from '~/assets/icons/home/tick.svg'
import WalletIcon from '~/assets/icons/home/wallet.svg'

import FullSectionContainer from '../FullSectionContainer'

import SecurityItem from './SecurityItem'
import msg from './SecuritySection.messages'

const SecuritySection = () => {
  const { formatMessage } = useIntl()

  const securityData = [
    {
      icon: <WalletIcon />,
      title: formatMessage(msg.titleSecurePayments),
      description: formatMessage(msg.descriptionSecurePayments)
    },
    {
      icon: <CalendarIcon />,
      title: formatMessage(msg.titleEasyBooking),
      description: formatMessage(msg.descriptionEasyBooking)
    },
    {
      icon: <KeyIcon />,
      title: formatMessage(msg.titlePrivacy),
      description: formatMessage(msg.descriptionPrivacy)
    },
    {
      icon: <TickIcon />,
      title: formatMessage(msg.titleVerified),
      description: formatMessage(msg.descriptionVerified)
    }
  ]

  return (
    <FullSectionContainer pt={{ xs: 4, md: 9 }} pb={{ xs: 8.5, md: 9 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Text
            size={{ xs: 'h3', md: 'h2' }}
            component="h2"
            color="textPrimary"
            textAlign={{ xs: 'center', md: 'left' }}
            fontWeight={600}
            maxWidth={{ md: 400 }}
            mb={2}
          >
            <FormattedMessage
              {...msg.title}
              values={{
                text: (children: string) => (
                  <TextBg
                    icon={<RedLineIcon />}
                    positionIcon={{
                      top: { xs: 0, md: 10 },
                      left: { xs: -10, md: 5 }
                    }}
                  >
                    {children}
                  </TextBg>
                )
              }}
            />
          </Text>

          <Text
            size={{ xs: 'body1' }}
            color="textSecondary"
            maxWidth={{ md: 445 }}
          >
            <FormattedMessage {...msg.description} />
          </Text>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack spacing={{ xs: 3.2, md: 3.6 }}>
            {securityData.map((item) => (
              <SecurityItem key={item.title} {...item} />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </FullSectionContainer>
  )
}

export default SecuritySection
