import React, { FC } from 'react'

import { Box, Stack } from '@mui/material'

import Text from '~/components/base/Text'

interface SecurityItemProps {
  icon: React.ReactNode
  title: string
  description: string
}

const SecurityItem: FC<SecurityItemProps> = ({ icon, title, description }) => {
  return (
    <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
      <Box width={27} height={27}>
        {icon}
      </Box>

      <Stack spacing={1.5}>
        <Text
          size={{ xs: 'h4' }}
          component="h3"
          fontWeight={700}
          color="text.primary"
          sx={{
            fontSize: { xs: 20, md: 24 },
            lineHeight: { xs: '24px', md: '32px' }
          }}
        >
          {title}
        </Text>

        <Text size={{ xs: 'body2' }} color="text.headline">
          {description}
        </Text>
      </Stack>
    </Stack>
  )
}

export default SecurityItem
