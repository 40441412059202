import { defineMessages } from 'react-intl'

const msg = defineMessages({
  title: {
    defaultMessage: '<text>Why</text> order your cake through CakerHQ?'
  },
  description: {
    defaultMessage:
      'When you order a cake with us you are ordering from a verified, trusted cake business and your payments are secured by CakerHQ.'
  },
  titleSecurePayments: {
    defaultMessage: 'Secure payments'
  },
  descriptionSecurePayments: {
    defaultMessage:
      'Payments are made through our 100% secure payment processing system.'
  },
  titleEasyBooking: {
    defaultMessage: 'Easy booking'
  },
  descriptionEasyBooking: {
    defaultMessage:
      'With just a few clicks you can book a cake or dessert for your next event.'
  },
  titlePrivacy: {
    defaultMessage: '100% privacy'
  },
  descriptionPrivacy: {
    defaultMessage: 'Your personal information is safe with us.'
  },
  titleVerified: {
    defaultMessage: 'Verified cake makers'
  },
  descriptionVerified: {
    defaultMessage: 'All baking businesses are 100% verified and trusted by us.'
  }
})

export default msg
